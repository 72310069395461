// extracted by mini-css-extract-plugin
export var focusInput100 = "landingStyles-module--focusInput100--3ohdR";
export var inputStyle = "landingStyles-module--inputStyle--2sjos";
export var alertValidate = "landingStyles-module--alertValidate--34o4D";
export var formContainer = "landingStyles-module--formContainer--3qUKI";
export var formBox = "landingStyles-module--formBox--2F6Sg";
export var wrapInput100 = "landingStyles-module--wrapInput100--1AFDu";
export var placeholder0 = "landingStyles-module--placeholder0--2ievL";
export var container = "landingStyles-module--container--_UK8N";
export var box = "landingStyles-module--box--2TZXw";
export var boxRight = "landingStyles-module--boxRight--2UqG2";
export var box1 = "landingStyles-module--box1--3sBqO";
export var buttonSubscribe = "landingStyles-module--buttonSubscribe--uQAzl";
export var formIframe = "landingStyles-module--formIframe--1hQLa";