import React, { useEffect } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import CountDownTimer from "../components/CountDownTimer"
import * as landingStyles from "../styles/landingStyles.module.css"
import PicsContainer from "../components/picsContainer"
import firebase from "gatsby-plugin-firebase"
import ContactLanding from "../components/ContactLanding"

const IndexPage = () => {
  useEffect(() => {
    firebase.analytics().logEvent("visited_home")
  }, [])

  return (
    <Layout>
      <Seo title="Home" />
      <div className={landingStyles.container}>
        <div className={landingStyles.box}>
          <div className={landingStyles.box1}>
            <h1>Coming soon</h1>
            <h3>Our website is currently under construction. Stay tuned.</h3>
          </div>
          <div className={landingStyles.formContainer}>
            <iframe
              className={landingStyles.formIframe}
              src="https://cdn.forms-content.sg-form.com/2c2c2797-e0e1-11eb-a365-8e3812ab66ea"
            />
          </div>
        </div>

        <div className={landingStyles.boxRight}>
          <PicsContainer />
        </div>
      </div>
      <ContactLanding />
    </Layout>
  )
}
export default IndexPage
