import React from "react"
import * as contactInfoStyles from "../styles/contactInfo.module.css"

const ContactLanding = () => {
  return (
    <div className={contactInfoStyles.container}>
      <div className={contactInfoStyles.box}>
        <h1>Office</h1>
        <p>36 W 47th St ste 204</p>
        <p> New York, NY, 10036</p>
      </div>
      <div className={contactInfoStyles.box}>
        <h1>Let's Talk</h1>
        <p>Phone: (800)724-1798</p>
        <p>
          <a href="mailTo:mywish@engagementwish.com">
            E-mail: mywish@engagementwish.com
          </a>
        </p>
      </div>
    </div>
  )
}

export default ContactLanding
